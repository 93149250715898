import * as React from "react"
import { addPropertyControls, ControlType, RenderTarget } from "framer"

/**
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight auto
 */
export default function Q8_Form_Test(props) {
    const {
        apikey,
        ctabtn,
        origincta,
        destinationcta,
        cssurl,
        css,
        language,
        id,
    } = props

    // We can't really do anything without a key
    if (!apikey) {
        return <div>Please set a form key</div>
    }

    // We use the Framer node id for a stable id
    const elementId = `q8form_${id}`

    // We use a script loader in a useEffect
    React.useEffect(() => {
        const container = document.getElementById(elementId)

        const stylesheetUrls = [
            "https://test.quot8.com/assets/embed_theme_modern.css",
            "https://test.quot8.com/assets/embed.css",
        ]
        // Loop through each stylesheet URL and create <link> elements
        stylesheetUrls.forEach((url) => {
            // Create a <link> element for the CSS file
            const linkElement = document.createElement("link")
            linkElement.rel = "stylesheet"
            linkElement.type = "text/css"
            linkElement.href = url

            // Append the <link> element to the <head> tag
            document.head.appendChild(linkElement)
        })

        // Add the library script and set up the load handler
        const script = document.createElement("script")
        script.src = "https://test.quot8.com/assets/embed.js"
        script.dataset.q8form = apikey
        if (cssurl) {
            script.dataset.q8css = cssurl
        } else {
            script.dataset.theme = "modern"
            script.dataset.color = "#ff7216"
        }
        if (language) {
            script.dataset.q8lang = language
        }
        // script.async = true
        // script.dataset.title = ""
        // script.dataset.redirect = "true"
        script.onload = () => {
            if (typeof window.q8SetReferer === "function") {
                if (document.cookie.length > 0) {
                    var match = document.cookie.match(
                        new RegExp("(^| )landing_url=([^;]+)")
                    )
                    if (match) {
                        var landingUrl = match[2]
                        window.q8SetReferer(landingUrl)
                    }
                }
            }
        }

        // Change button text
        if (ctabtn) {
            const observer = new MutationObserver((mutations) => {
                if (
                    Array.from(
                        document.querySelectorAll(
                            ".q8form__field.q8form__field--heading h3"
                        )
                    ).find((el) => el.textContent === "Select countries")
                ) {
                    Array.from(
                        document.querySelectorAll(
                            ".q8form__field.q8form__field--heading h3"
                        )
                    )
                        .find((el) => el.textContent === "Select countries")
                        .closest(".q8form__field.q8form__field--heading")
                        .remove()
                }

                var originSelect = document.getElementById("origin-country")
                if (origincta && originSelect) {
                    var originFirstOption = originSelect.options[0]
                    if (
                        originFirstOption &&
                        originFirstOption.getAttribute("value") === null &&
                        originFirstOption.textContent !== origincta
                    ) {
                        document
                            .querySelector(`label[for="origin-country"]`)
                            ?.remove()
                        originFirstOption.textContent = origincta
                    }
                }

                var destinationSelect = document.getElementById(
                    "destination-country"
                )
                if (destinationcta && destinationSelect) {
                    var destinationFirstOption = destinationSelect.options[0]
                    if (
                        destinationFirstOption &&
                        destinationFirstOption.getAttribute("value") === null &&
                        destinationFirstOption.textContent !== destinationcta
                    ) {
                        document
                            .querySelector(`label[for="destination-country"]`)
                            ?.remove()
                        destinationFirstOption.textContent = destinationcta
                    }
                }

                // if (
                //     document.querySelector(
                //         ".q8form__step--0 .q8form__button--next"
                //     )
                // ) {
                //     document.querySelector(
                //         ".q8form__step--0 .q8form__button--next"
                //     ).textContent = ctabtn
                //     document.querySelector(
                //         ".q8form__step--0 .q8form__button--next"
                //     ).style.background =
                //         "linear-gradient(155deg, #fff 0%, #5934FF 35%, #5934FF 55%, #22201f 100%)"
                //     observer.disconnect()
                // }
            })
            observer.observe(container, {
                attributes: false,
                characterData: false,
                childList: true,
                subtree: true,
                attributeOldValue: false,
                characterDataOldValue: false,
            })
        }

        container.innerHTML = ""
        container.appendChild(script)

        if (css) {
            const style = document.createElement("style")
            style.type = "text/css"
            if (style.styleSheet) {
                // This is required for IE8 and below.
                style.styleSheet.cssText = css
            } else {
                style.appendChild(document.createTextNode(css))
            }
            container.appendChild(style)
        }

        return () => {
            stylesheetUrls.forEach((url) => {
                const linkElement = document.querySelector(
                    `link[href="${url}"]`
                )
                if (linkElement) {
                    document.head.removeChild(linkElement)
                }
            })
            container.removeChild(script)
            script.remove()
            container.remove()
            if (ctabtn && typeof observer !== "undefined") {
                observer.disconnect()
            }

            if (typeof window.q8loaded !== "undefined") {
                window.q8loaded = []
            }
        }
    }, [apikey, ctabtn, id])

    return <div initial={false} style={style} id={elementId} />
}

const style = {
    width: "100%",
    height: "100%",
    minHeight: "50",
    fontSize: "16px",
}

// Add a form id field in the properties panel
addPropertyControls(Q8_Form_Test, {
    apikey: {
        title: "API key",
        type: ControlType.String,
        placeholder: "API key",
        //defaultValue: "",
    },
    ctabtn: {
        title: "CTA Text",
        type: ControlType.String,
        //defaultValue: "",
    },
    origincta: {
        title: "Origin country selector text",
        type: ControlType.String,
        //defaultValue: "",
    },
    destinationcta: {
        title: "Destination country selector text",
        type: ControlType.String,
        //defaultValue: "",
    },
    cssurl: {
        title: "URL to CSS file",
        type: ControlType.String,
        //defaultValue: "",
    },
    css: {
        title: "Custom CSS style",
        type: ControlType.String,
        displayTextArea: true,
        //defaultValue: "",
    },
    language: {
        title: "Form language",
        type: ControlType.String,
        displayTextArea: true,
        defaultValue: "en",
    },
})
